<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Code</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input class="w-full" :disabled="!is_create" v-model="combo.code" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Name</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input class="w-full" :disabled="!(is_create||selected.isEdit)" v-model="combo.name" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Valid From</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <Datepicker
            type="date"
            class="w-full"
            :inline="false"
            :disabledDates="dateDisabelValidFrom"
            :disabled="!(is_create||selected.isEdit)"
            v-model="combo.valid_from"
            format="dd-MM-yyyy"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Valid To</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <Datepicker
            type="date"
            class="w-full"
            :inline="false"
            v-model="combo.valid_to"
            :disabledDates="dateDisabelValidTo"
            :disabled="!(is_create||selected.isEdit)"
            format="dd-MM-yyyy"
          />
        </div>
      </div>

      <!-- territories -->
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <multiselect
            :multiple="true"
            :taggable="true"
            track-by="name"
            class="selectExample"
            v-model="combo.territories"
            :options="territory"
            :allow-empty="false"
            :group-select="false"
            :max-height="120"
            @input="onInputTerr"
            placeholder=" Type to search"
            :disabled="!(is_create||selected.isEdit)"
            label="name"
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title"
                  >{{ dt.option.code }} {{ dt.option.name }}</span
                >
              </span>
            </template>
          </multiselect>
        </div>
      </div>
      <!--
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Daily/Periodly</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-switch color="success" v-model="combo.daily_periodly">
            <span slot="on">Daily</span>
            <span slot="off">Periodly</span>
          </vs-switch>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Limit Per Customer</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vue-number-input
            inline
            controls
            :min="1"
            :step="1"
            size="small"
            v-model="combo.limit_per_customer"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Limit Per Branch</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vue-number-input
            inline
            controls
            :min="1"
            :step="1"
            size="small"
            v-model="combo.limit_per_branch"
          />
        </div>
      </div> -->
      <!-- Image -->
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Upload Image</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <input :disabled="!(is_create||selected.isEdit)" type="file" @change="onFileChange" />
        </div>
      </div>

      <!-- image preview -->
      <div class="vx-row mb-6" v-show="combo.image_url!=''">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Image Preview</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <img
            :src="combo.image_url"
            alt="image preview"
            width="200"
            height="200"
          />
        </div>
      </div>
      <div class="vx-row mb-6" v-show="is_create||selected.isEdit">
        <div class="vx-col sm:w-6/6 w-full">
            <a href="#" class="m-2"
              style="float: right;"
              color="success"
              type="border"
              icon-pack="feather"
              icon="icon-plus"
              @click="viewListItem"
              >Add Item</a>
        </div>
      </div>
      <div class="vx-row mb-6">

        <vs-table stripe bordered :data="combo.items" style="width: 100%">
          <template slot="thead">
            <vs-th>Code</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Qty </vs-th>
            <vs-th>Handling Unit</vs-th>
            <vs-th>Type </vs-th>
            <vs-th>Action</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="index" v-for="(dt, index) in data">
              <vs-td>{{
                dt.item_code ? dt.item_code : dt.sku_code
              }}</vs-td>
              <vs-td>{{ dt.item_name ? dt.item_name : dt.name }}</vs-td>
              <vs-td>
                <vue-number-input
                  inline
                  controls
                  :min="1"
                  :step="1"
                  :disabled="!(is_create||selected.isEdit)"
                  size="small"
                  v-model="combo.items[index].quantity"
                />
              </vs-td>
              <vs-td>{{ dt.unit_name? dt.unit_name : "" }}</vs-td>
              <vs-td>{{
                dt.type === 1 ? "Freegood" : dt.type === "L" ? "Regular" : ""
              }}</vs-td>
              <vs-td>
                <!-- <vx-tooltip v-if="dt.formInputs[0].value.code !== undefined" text="Delete Item">
                  <vs-button
                    size="small"
                    color="red"
                    icon-pack="feather"
                    icon="icon-trash"
                    class="mt-2"
                  />
                </vx-tooltip> -->
                <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click="handleDeleteLine(index)"/>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
      <vs-popup fullscreen title="List Items" :active.sync="showListItems">
      <div class="vx-row m-2 mt-6">
        <div class="vx-col w-full">
          <ListItems @list-select="handleItemSelect" @reload-data="reloadData" :dataitems="dataitems" :params="params" @search="handleSearch"></ListItems>
        </div>
      </div>
    </vs-popup>

      <div class="vx-row mb-6" v-show="is_create||this.selected.isEdit">
        <div class="vx-col sm:w-6/6 w-full">
          <vs-button
            class="mr-3 mb-2"
            @click="submitCombo()"
            :disabled="btn_disable"
            >{{ selected.isEdit?(is_create?"Create":"Update"):Submit }}</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueNumberInput from "@chenfengyuan/vue-number-input";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import ListItems from "./list_items.vue"

export default {
  components: {
    VueNumberInput,
    Datepicker,
    ListItems,
  },
  props: {
    selected: Object,
    option: Object,
  },
  async mounted() {
    await this.fetchTerritory();
    if (this.selected.isEdit&&!this.selected.itemKit) {
      this.is_create = true
      // this.addItemsToLines()
    }
    this.getItem()
    // if (Object.keys(this.selected.itemKit).length > 0) {
    //   this.isEditorShow = true;
    //   await this.editOrShow();
    // }
  },
  destroyed() {
    this.selected.isEdit = true;
    this.selected.itemKit = {};
  },
  data: () => ({
    dataitems:[],
    imageBase64:"",
    imageName:"",
    btn_disable:false,
    showListItems:false,
    is_create:false,
    dateDisabelValidTo:{
      to: new Date()
    },
    dateDisabelValidFrom:{
      from: new Date()
    },
    is_preview:false,
    combo: {
      id: 0,
      code: "",
      name: "",
      company_id: 0,
      valid_from: new Date(),
      valid_to: new Date(),
      territories: [],
      items: [],
      image_name: null,
      image_path: null,
      image_url: null,
      daily_periodly: true,
      limit_per_customer: 1,
      limit_per_branch: 1,
      image_base64:"",
    },
    params: {
        length: 10,
        page: 1,
        search: "",
        order: "desc",
        sort: "id",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 0,
        end: 0,
        territory_id: null,
    },
    header: [
      {
        text: "Code",
        // value: "sku_code"
        sortable: false,
      },
      {
        text: "Name",
        // value: "item_name"
        sortable: false,
      },
      {
        text: "Unit",
        // value: "name"
        sortable: false,
      },
      // {
      //   text: "Action",
      //   sortable: false,
      // },
    ],
    responseData: {},
    counterDanger: false,
    indexDelete: "",
    itemsCosumable: false,
    isEditorShow: false,
    territory: [],
    unit: [],
    optionItemAvailableShow: false,
    tableItemUnit: {
      data: [],
      length: 10,
      page: 1,
      search: "",
      order: "id",
      sort: "desc",
      total: 0,
      totalPage: 0,
      totalSearch: 0,
      limits: [10, 25, 50, 100, "All"],
      start: 1,
      end: 0,
    },
  }),
  computed: {},
  watch: {
    "combo.image_url":function(v){
      if (v!="") {
        this.is_preview = true
      }
    },
    "combo.valid_to":function(v){
      this.dateDisabelValidFrom.from = new Date(this.combo.valid_to)
    },
    "combo.valid_from":function(v){
      this.dateDisabelValidTo.to = new Date(this.combo.valid_from)
    },
    territory: function (v) {
      console.log(this.selected)
      if (this.selected.itemKit&&Object.keys(this.selected.itemKit).length > 0) {
        this.isEditorShow = true;
        this.editOrShow();
      }
    },
  },
  methods: {
    handleDeleteLine(idx) {
        this.combo.items.splice(idx, 1);
    },
    handleItemSelect(datas = [],dataRemove = []){
      this.$vs.loading();
      this.showListItems = false
      let item = {}
      // this.combo.items = []
      let tempIDs =[]
      let tempIDs2 =[]
      let idRemoves = []
      dataRemove.forEach((el)=>{
        idRemoves.push(el.item_unit_id)
      })
      this.combo.items.forEach((el)=>{
        tempIDs2.push(el.item_unit_id)
      })
      console.log("data line ids",tempIDs2)
      datas.forEach((el)=>{
        tempIDs.push(el.item_unit_id)
        item ={
          item_id:el.id,
          sku_code:el.sku_code,
          item_name:el.name,
          quantity:0,
          item_unit_id:el.item_unit_id,
          unit_name:el.unit_name,
          is_check:true,
          type:"L"
        }
        console.log("!data line ids includes[",item.item_unit_id,"]",tempIDs2.includes(item.item_unit_id))
        if (!(tempIDs2.includes(item.item_unit_id))) {
          this.addItemsToLines(item)
        }
      })
      if (idRemoves.length>0) {
        this.removeItemByItemUnitIDs(idRemoves)
      }
      this.dataitems.forEach((el,i)=>{
        this.dataitems[i].is_check = false
        if (tempIDs.includes(el.item_unit_id)){
          this.dataitems[i].is_check = true
        }
      })
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
    handleSearch(search){
      this.params.search = search
      this.params.page = 1
      this.getItem()
    },
    viewListItem(){
      this.showListItems = true
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      console.log(file)
      const reader = new FileReader();
      reader.onloadend = () => {
        this.imageBase64 =reader.result
        let temp = this.imageBase64.split(",")
        if (temp.length>0) {
          this.imageBase64 = temp[temp.length-1]
        }
      };
      reader.readAsDataURL(file);
      this.imageName =file.name
    },
    postImage(file) {
      let formData = new FormData();
      formData.append("file", file);
      this.$vs.loading();
      this.$http
        .post("/api/v1/master/combo/upload-image", formData)
        .then((response) => {
          this.$vs.loading.close();
          this.combo.image_name = response.data.file_name;
          this.combo.image_path = response.data.path;
          this.combo.image_url = response.data.url;
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    fetchTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/multiple-territory")
        .then((response) => {
          this.territory = []
          this.territory.push({
            address:"",
            city:"",
            code:"",
            company_id:0,
            country:"",
            description:"",
            district:"",
            id:0,
            name:"ALL",
            postal_code:"",
            province:"",
            sub_district:"",
            territory_area_id:0,
          })
          if (response.data.records.length>0) {
            this.territory.push(...response.data.records)
          }
          this.$vs.loading.close();
          console.log("fetchTerritory");
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    // async setItemsTable(params) {
    //   this.params = params;
    //   var url = "/api/v1/item-available";
    //   const resp = await this.$http.get(url, {
    //     params: {
    //       search: params.search,
    //       limit: params.length,
    //       offset: params.length * (params.page - 1),
    //       order: params.sort,
    //       sort: params.order,
    //       territory_code: this.selected.territory.code,
    //       territory_id: this.selected.territory.ID,
    //       item_consumable: this.itemsCosumable,
    //     },
    //   });
    //   if (resp.code == 200) {
    //     this.responseData = {
    //       data: resp.data.ItemsAvailable,
    //       recordsTotal: resp.data.RecordsTotal,
    //       length: resp.data.ResultsTotal,
    //     };
    //   }
    // },
    async addItemsToLines(items) {
      console.log("items",items);
      console.log("this.combo.items",this.combo.items);
      if (!this.selected.isEdit) {
        return true;
      }

      // check if item already exist, item_id & unit_id
      var checkResult = this.combo.items.every((item) => {
        return item.item_id != items.item_id || item.item_unit_id != items.item_unit_id;
      });

      if (!checkResult) {
        // await this.$vs.notify({
        //   title: "Error",
        //   text: "Item already exist",
        //   color: "danger",
        // });
        return true;
      }

      this.combo.items.push(items);
      var index = this.combo.items.indexOf(items);
      console.log("<<<<>>>>",this.combo.items[index],"index",index)
      this.combo.items[index].quantity = 1;

    },
    removeItemByItemUnitIDs(ids = []){
      console.log("id removes",ids)
      this.combo.items = this.combo.items.filter((el)=>{
          return !ids.includes(el.item_unit_id)
        })
    },
    async deleteItemLine(index, dt) {
      this.indexDelete = index;
      await this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure remove " + dt.name + " from lines?",
        accept: this.confirmDelete,
      });
    },
    async confirmDelete() {
      await this.$vs.loading();

      setTimeout(() => {
        this.$vs.loading.close();
        if (this.combo.items[this.indexDelete].ID) {
          this.combo.delete_items.push(this.combo.items[this.indexDelete]);
        }
        this.combo.items.splice(this.indexDelete, 1);
      }, 1000);
    },
    async validateData() {
      // adjust territories values
      var territories = [];
      this.combo.territories.forEach((element) => {
        territories.push(element.id);
      });

      this.combo.territories = territories;

      // adjust items values
      var items = [];
      this.combo.items.forEach((element) => {
        items.push({
          quantity: element.quantity,
          item_unit_id: element.item_unit_id,
        });
      });
      this.combo.items = items;
    },
    async submitCombo() {
      this.btn_disable = true
      // validate
      if (this.combo.items.length == 0) {
        let errorMessage = "Items can't be empty";
        await this.$vs.notify({
          title: "Danger",
          text: errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        // this.$emit("changeTab", 0, { name: "All" }, {}, false);
        this.btn_disable = false
        return false;
      }

      if (this.combo.territories.length == 0) {
        let errorMessage = "territory can't be empty";
        await this.$vs.notify({
          title: "Danger",
          text: errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        // this.$emit("changeTab", 0, { name: "All" }, {}, false);
        this.btn_disable = false
        return false;
      }

      if (this.combo.name == "") {
        let errorMessage = "name can't empty";
        await this.$vs.notify({
          title: "Danger",
          text: errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        // this.$emit("changeTab", 0, { name: "All" }, {}, false);
        this.btn_disable = false
        return false;
      }
      if (this.combo.code == "") {
        let errorMessage = "code can't empty";
        await this.$vs.notify({
          title: "Danger",
          text: errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        // this.$emit("changeTab", 0, { name: "All" }, {}, false);
        this.btn_disable = false
        return false;
      }

      if (this.combo.valid_from == "") {
        let errorMessage = "valid from can't empty";
        await this.$vs.notify({
          title: "Danger",
          text: errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        // this.$emit("changeTab", 0, { name: "All" }, {}, false);
        this.btn_disable = false
        return false;
      }

      if (this.combo.valid_to == "") {
        let errorMessage = "valid to can't empty";
        await this.$vs.notify({
          title: "Danger",
          text: errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        // this.$emit("changeTab", 0, { name: "All" }, {}, false);
        this.btn_disable = false
        return false;
      }
      const tempTerrs = this.combo.territories
      const tempItems = this.combo.items
      await this.validateData();

      // set company_id
      this.combo.company_id = this.$store.state.user.currentUser.company_id;
      this.combo.daily_periodly = this.combo.daily_periodly
        ? this.combo.daily_periodly == true
          ? 1
          : 2
        : 2;

      if (this.combo.limitPerCustomer == 0) {
        let errorMessage = "limit Per Customer can't 0";
        await this.$vs.notify({
          title: "Danger",
          text: errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        // this.$emit("changeTab", 0, { name: "All" }, {}, false);
        this.btn_disable = false
        return false;
      }
      if (this.combo.limitPerBrach == 0) {
        let errorMessage = "limit Per Branch can't 0";
        await this.$vs.notify({
          title: "Danger",
          text: errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        // this.$emit("changeTab", 0, { name: "All" }, {}, false);
        this.btn_disable = false
        return false;
      }
      this.combo.image_base64 = this.imageBase64
      this.combo.image_name = this.imageName
      this.$vs.loading()
      this.$http
        .post("api/v1/master/combos", JSON.stringify(this.combo))
        .then((resp) => {
          this.btn_disable = false
          this.combo.territories=tempTerrs
          this.combo.items=tempItems
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$emit("changeTab", 0, { name: "All" }, {}, false);
          } else {
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        });

      this.$vs.loading.close();
      // this.$emit("changeTab", 0, { name: "All" }, {}, false);
    },
    editOrShow() {
      this.$http
        .get("api/v1/master/combos/" + this.selected.itemKit.id)
        .then((resp) => {
          if (resp.code == 200) {
            this.combo.items = [];
            let combo = resp.data;
            this.combo.id = combo.id;
            this.combo.name = combo.name;
            this.combo.code = combo.code;
            this.combo.image_url = combo.image_url;
            this.combo.valid_from = moment(combo.valid_from).format(
              "YYYY-MM-DD"
            );
            this.combo.valid_to = moment(combo.valid_to).format("YYYY-MM-DD");
            this.combo.daily_periodly = combo.daily_periodly
              ? combo.daily_periodly === 1
              : false;

            this.combo.limit_per_customer = combo.limit_per_customer;
            this.combo.limit_per_branch = combo.limit_per_branch;
            // set relation
            // this.combo.territories = combo.territories;
            if (combo.items) {
              combo.items.forEach((el,i) => {
                combo.items[i].is_check = true
              });
            }
            this.combo.items = combo.items;

            this.combo.territories = [];
            combo.territories.forEach((element) => {
              this.territory.forEach((item) => {
                if (element.territory_id == item.id) {
                  this.combo.territories.push(item);
                }
              });
              console.log(element.territory_id);
            });

            this.isEditorShow = true;
            // this.$vs.loading.close();
          } else {
            // this.$vs.loading.close();
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
          this.getItem()
        });
    },
    reloadData(params){
      this.params = params
      this.getItem()
    },
    getItem() {
      this.$vs.loading()
      this.$http
      .get("api/v1/master/combos/list-item", { params: this.params,})
      .then((resp) => {
          this.dataitems =[]
          this.$vs.loading.close();
          if (resp.code == 200) {
            if (resp.data.records) {
              let item ={}
              let tempIDs = []
                this.combo.items.forEach((el,i)=>{
                  tempIDs.push(el.item_unit_id)
                })
              resp.data.records.forEach((el)=>{
                item = {
                  id:el.id,
                  sku_code:el.sku_code,
                  item_unit_id:el.item_unit_id,
                  unit_name:el.unit_name,
                  name:el.name,
                  is_check:false,
                }
                if (tempIDs.includes(item.item_unit_id)) {
                  item.is_check = true
                }
                this.dataitems.push(item)
              });
              console.log("this.dataitems",this.dataitems);
              this.params.total = resp.data.total_record;
              this.params.totalPage = resp.data.total_page?resp.data.total_page:0;
              this.params.totalSearch = resp.data.total_record_search;
              this.params.length = resp.data.total_record_per_page;
              if (this.params.totalPage==0) {
                if (this.params.total>0) {
                  this.params.totalPage = Math.ceil(this.params.total/this.params.length)
                }else{
                  this.params.totalPage = 1
                }
              }
            }
          } else {
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          console.log("params>>>>",this.params)
      });
    },
    onInputTerr(v){
      console.log(v)
      if (v.length > 0 && v[v.length-1].id == 0) {
          this.combo.territories = this.combo.territories.filter((v)=>{
              return v.id ==0
          })
      }else{
          this.combo.territories = this.combo.territories.filter((v)=>{
              return v.id !=0
          })
      }
    }
  },
};
</script>
